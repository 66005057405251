import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link, Trans } from "gatsby-plugin-react-i18next";
import PageLayout from "../components/page-layout/PageLayout";
import Title from "../components/typography/Title";
import { isNil } from "lodash";
import TextHero from "../components/hero/TextHero";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";

interface BlogPageProps {
  pageContext: {
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
    category: {
      name: string | null;
      slug: string | null;
    };
    categories: {
      name: string | null;
      slug: string | null;
    }[];
    articles: {
      timeToRead: number;
      slug: string;
      title: string;
      description: string;
      category: string;
      date_published: string;
      image: string;
      author: {
        image: string;
        name: string;
      };
    }[];
  };
}

const BlogPage: React.FunctionComponent<BlogPageProps> = ({ pageContext }) => {
  const { articles, numPages, currentPage, categories, category } = pageContext;
  const { t } = useTranslation();

  const getPageLink = (page: number) =>
    ["/blog", category?.slug, page === 1 ? null : page]
      .filter((s) => !isNil(s))
      .join("/");

  const metaNamespace = category.slug ?? "all";

  return (
    <PageLayout pageName="blog" headerBg="gray-100">
      <SEO
        titleI18nKey={`blog:meta.${metaNamespace}.title`}
        descriptionI18nKey={`blog:meta.${metaNamespace}.description`}
        datePublished={new Date("2022-04-01T08:00:00Z")}
      />

      <TextHero
        tag={t("blog:hero.tag")}
        title={
          <Trans
            i18nKey="blog:hero.title"
            components={{ ol: <span className="text-ol-gradient" /> }}
          />
        }
        description={t("blog:hero.content")}
      />

      <section className="container px-5 my-24 mx-auto">
        <div className="lg:px-7 flex flex-col md:flex-row md:space-x-12">
          <div className="w-full md:w-52 shrink-0 mb-12">
            <Title large type="h4">
              {t("blog:categories")}
            </Title>
            <ul
              role="list"
              className="mt-6 text-sm font-medium text-gray-700  space-y-4 pb-6 border-b border-gray-200"
            >
              <Link to={`/blog`}>
                <li
                  className={
                    !category.slug
                      ? "font-extrabold py-2 text-gray-900"
                      : " py-2 hover:text-gray-900"
                  }
                >
                  {t("blog:categoryAll")}
                </li>
              </Link>
              {categories.map((cat, i) => (
                <Link key={`blg-cat-${i}`} to={`/blog/${cat.slug}`}>
                  <li
                    key={cat.slug}
                    className={
                      cat.slug === category?.slug
                        ? "font-extrabold py-2 text-gray-900"
                        : " py-2 hover:text-gray-900"
                    }
                  >
                    {cat.name}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div>
            <div className="grid gap-8 lg:grid-cols-2 mb-12">
              {articles.map((post) => {
                return (
                  <div
                    key={post.title}
                    className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                  >
                    <div className="flex-shrink-0">
                      <img
                        className="h-48 w-full object-cover"
                        src={
                          require(`../assets/images/blog/${post.image}`).default
                        }
                        alt=""
                      />
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <p className="text-sm text-primary tracking-widest font-medium uppercase">
                          {post.category}
                        </p>
                        <Link to={`/blog/${post.slug}`} className="block">
                          <p className="text-xl font-extrabold text-gray-900">
                            {post.title}
                          </p>
                          <p className="mt-3 text-base text-gray-600 text-justify">
                            {post.description}
                          </p>
                        </Link>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <span className="sr-only">{post.author.name}</span>
                          <img
                            className="h-10 w-10 rounded-full"
                            src={
                              require(`../assets/images/authors/${post.author.image}`)
                                .default
                            }
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            {post.author.name}
                          </p>
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <time dateTime={post.date_published}>
                              {new Date(
                                post.date_published
                              ).toLocaleDateString()}
                            </time>
                            <span aria-hidden="true">&middot;</span>
                            <span>{post.timeToRead} min</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Pagination
              totalPageCount={numPages}
              currentPage={currentPage}
              pageLinkGetter={getPageLink}
            />
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default BlogPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
